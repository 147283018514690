<template>
	<div class="company">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>会社概要</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<a href="/">Home</a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">
									会社概要
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-10 offset-md-1">
						<table class="table table-striped">
							<tbody>
								<tr>
									<th>会社名</th>
									<td>有限会社アシスト</td>
								</tr>
								<tr>
									<th>店名</th>
									<td>ASSIST</td>
								</tr>
								<tr>
									<th>代表者</th>
									<td>代表取締役&nbsp;&nbsp;高澤&nbsp;嘉英</td>
								</tr>
								<tr>
									<th>設立</th>
									<td>1999年9月2日</td>
								</tr>
								<tr>
									<th>資本金</th>
									<td>1,000万円</td>
								</tr>
								<tr>
									<th>所在地</th>
									<td>
										<dl>
											<dt>ショールーム（受付等）</dt>
											<dd class="mt-2 ml-2">
												〒607-8067 京都府京都市山科区音羽前田町48-1（
												<a href="http://goo.gl/ryxqF0" target="_blank"
													>Google Maps</a
												>）
											</dd>
											<dt>メイン工場（COOLGUSH）</dt>
											<dd class="mt-2 ml-2">
												〒607-8067 京都府京都市山科区音羽前田町35-1（
												<a href="http://goo.gl/nHPfDu" target="_blank"
													>Google Maps</a
												>）
											</dd>
										</dl>
									</td>
								</tr>
								<tr>
									<th>営業時間</th>
									<td>10：00～18：00</td>
								</tr>
								<tr>
									<th>定休日</th>
									<td>月曜日、第2・4火曜日</td>
								</tr>
								<tr>
									<th>電話</th>
									<td>075-582-7788</td>
								</tr>
								<tr>
									<th>FAX</th>
									<td>075-320-2978</td>
								</tr>
								<tr>
									<th>E-MAIL</th>
									<td>
										mail@assist-kyoto.co.jp
										<br />
										<a href="https://form.run/@assist-kyoto" target="_blank"
											>CONTACTフォームはこちら</a
										>
									</td>
								</tr>
								<tr>
									<th>URL</th>
									<td>
										<a href="https://assist-kyoto.co.jp"
											>https://assist-kyoto.co.jp</a
										>
									</td>
								</tr>
								<tr>
									<th>登録</th>
									<td>
										適格請求書発行業者登録番号&nbsp;T3-1300-0201-1830
										<br />京都府公安委員会／古物商&nbsp;第611109910049号
										<br />近畿運輸局／認証工場&nbsp;近運&nbsp;整認京&nbsp;第2818号
										<br />近畿運輸局／認証工場&nbsp;近運&nbsp;整認京&nbsp;第3239号
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<!-- Blog Sidebar Area -->
					<!--<div class="col-12 col-md-4">
						<Sidebar></Sidebar>
					</div>-->
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
//import Sidebar from "@/components/Sidebar.vue";

export default {
	title: "会社概要",
	components: {
		//Sidebar,
	},
};
</script>
